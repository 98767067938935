<template>
  <ion-page>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-align-items-center mb-4 border-bottom">
          <ion-col>
            <ion-label class="fw-600 fs-3 text-gray-700">
              {{ $t('top_up_credits') }}
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-icon
              @click="$emit('closeModal')"
              size="large"
              class="f-icon"
              :icon="closeCircleOutline"
            ></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row class="mx-1">
          <ion-col>
            <ion-label class="text-gray-700 fs-2">{{ $t('add_cust_credit_to') }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row class="mx-1 mb-3">
          <ion-col>
            <ion-label class="primary text-primary-green-800 fw-500 fs-2">{{
              selectedCompany.customerName
            }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row class="border-bottom pb-2">
          <div class="box px-2 py-2">
            <ion-label position="stacked" class="text-gray-700 mt-2">{{ $t('input_amount') }}</ion-label>
            <div class="d-flex align-center input">
              <ion-text class="pr-1 fw-500 text-gray-700">{{ selectedCompany.currencySymbol }}</ion-text>
              <ion-input
                mode="ios"
                ref="inputAmountRef"
                :clear-input="true"
                placeholder="0.00"
                name="amount"
                inputmode="decimal"
                v-model="selectedCompany.totalPaidAmount"
                @ionInput="onInputAmount($event)"
              ></ion-input>
            </div>
          </div>
        </ion-row>
      </ion-grid>
      <div class="mx-2 mt-4">
        <ion-button class="primary w-100" @click="onTopUpCredit" :disabled="!selectedCompany.totalPaidAmount">
          <ion-label class="text-capitalize fw-600">{{ $t('continue') }}</ion-label>
        </ion-button>
        <ion-button class="primary w-100" fill="outline" @click="$emit('closeModal')">
          <ion-label class="text-capitalize fw-600">{{ $t('cancel') }}</ion-label>
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { priceFormatter } from '@/utils/';
import { closeCircleOutline } from 'ionicons/icons';
import { defineComponent, inject, nextTick, onMounted, ref } from 'vue';
export default defineComponent({
  name: 'ModalTopUpCredit',
  emits: ['onTopUpApplied', 'closeModal'],
  setup(_, { emit }) {
    const storage = inject('$storage');
    const selectedCompany = ref({
      tenantId: null,
      customerId: null,
      customerName: null,
      currencySymbol: null,
      totalPaidAmount: ''
    });
    const inputAmountRef = ref(null);
    const getDataStorage = async () => {
      await storage.getUser().then(({ currency_symbol, tenant }) => {
        selectedCompany.value.currencySymbol = currency_symbol;
        selectedCompany.value.tenantId = tenant.id;
      });
      await storage.getSelectedCompany().then(({ id, name, branch }) => {
        selectedCompany.value.customerId = id;
        selectedCompany.value.customerName = name || branch;
      });
    };
    const onTopUpCredit = () => {
      emit('onTopUpApplied', selectedCompany.value);
      emit('closeModal');
    };
    const onInputAmount = (event) => {
      const sanitizedValue = event.target.value.replace(/[^0-9.,]/g, '');
      const parts = sanitizedValue.split(/[.,]/);
      if (parts.length > 1) {
        const integerPart = parts[0];
        const decimalPart = parts[1].substring(0, 2);
        event.target.value = `${integerPart}.${decimalPart}`;
      } else if (parts.length === 1 && parts[0] !== '0') {
        event.target.value = sanitizedValue;
      } else {
        event.target.value = '';
      }
    };
    const loadFocus = () => {
      nextTick(() => {
        const inputFocus = inputAmountRef.value.$el;
        inputFocus.setFocus();
      });
    };
    onMounted(async () => {
      getDataStorage();
      await loadFocus();
    });
    return {
      loadFocus,
      inputAmountRef,
      onInputAmount,
      closeCircleOutline,
      priceFormatter,
      onTopUpCredit,
      selectedCompany
    };
  }
});
</script>
<style lang="scss" scoped>
ion-item {
  width: 95%;
  border: 1px solid rgb(139, 139, 139);
  border-radius: 10px;
  margin-bottom: 1rem;
}
.align-top {
  margin-top: -10px !important;
}
.box {
  width: 95%;
  border: 1px solid rgb(139, 139, 139);
  border-radius: 10px;
  margin-bottom: 1rem;
  margin: 0 auto;
}
.border-bottom {
  border-bottom: 1px solid var(--ion-color-text-gray-300);
}

.f-icon {
  color: gray;
}
.f-orange {
  color: #eb8c31;
}
ion-button {
  --border-radius: 10px;
  width: 95%;
  height: 44px;
}
ion-textarea {
  --placeholder-color: var(--ion-color-text-gray-600);
}
</style>
