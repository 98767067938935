<template>
  <ion-modal
    mode="ios"
    :initial-breakpoint="0.7"
    :is-open="isShowInputCredit"
    :backdropDismiss="true"
    @didDismiss="$emit('closeModal')"
    class="modal-radius-md"
  >
    <div>
      <ion-grid>
        <ion-row class="ion-align-items-center mb-4 border-bottom">
          <ion-col>
            <ion-label class="fw-600 fs-3 text-gray-700">
              {{ $t('apply_available_credit') }}
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-icon
              @click="$emit('closeModal')"
              size="large"
              class="f-icon"
              :icon="closeCircleOutline"
            ></ion-icon>
          </ion-col>
        </ion-row>
        <ion-row>
          <div class="box px-2 py-2">
            <ion-label position="stacked" class="text-gray-700 mt-2">{{ $t('input_amount') }}</ion-label>
            <div class="d-flex align-center input">
              <ion-text class="pr-1 fw-500 text-gray-700">{{ currencySymbol }}</ion-text>
              <ion-input
                type="number"
                :clear-input="true"
                placeholder="0.00"
                name="amount"
                v-model="initialCreditValue"
                inputmode="decimal"
                @ionInput="onInputAmount($event)"
              ></ion-input>
            </div>
          </div>
          <ion-label class="f-orange ml-4 mt-2 mb-4 fw-500"
            >{{ $t('remaining_credits') }} {{ priceFormatter(currencySymbol, afterCreditChangeValue) }}
          </ion-label>
        </ion-row>
      </ion-grid>
      <div class="d-flex align-center justify-center mt-5">
        <ion-button class="primary" @click="onApplyCreditBalance">
          <ion-label class="text-capitalize fw-600">{{ $t('apply') }}</ion-label>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>
<script>
import { priceFormatter } from '@/utils/';
import { closeCircleOutline } from 'ionicons/icons';
import { defineComponent, ref, watch } from 'vue';
export default defineComponent({
  name: 'ModalInputCredits',
  emits: ['onUseCreditBalance', 'closeModal'],
  props: {
    isShowInputCredit: {
      type: Boolean,
      default: false
    },
    initialCredit: {
      // cust credit balance - total paid invoices on its initial state
      type: Number,
      default: 0
    },
    remainingCredits: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const initialCreditValue = ref(props.initialCredit === 0 ? '' : props.initialCredit.toFixed(2));
    const afterCreditChangeValue = ref(props.remainingCredits - props.initialCredit);
    const onApplyCreditBalance = () => {
      emit('onUseCreditBalance', +initialCreditValue.value);
      emit('closeModal');
    };
    const onInputAmount = (event) => {
      const sanitizedValue = event.target.value.replace(/[^0-9.,]/g, '');
      const parts = sanitizedValue.split(/[.,]/);
      if (parts.length > 1) {
        const integerPart = parts[0];
        const decimalPart = parts[1].substring(0, 2);
        event.target.value = `${integerPart}.${decimalPart}`;
      } else if (parts.length === 1 && parts[0] !== '0') {
        event.target.value = sanitizedValue;
      } else {
        event.target.value = '';
      }
    };
    watch(initialCreditValue, (value) => {
      if (value === '') {
        afterCreditChangeValue.value = props.remainingCredits;
      }
      afterCreditChangeValue.value = +props.remainingCredits - +value;
    });
    return {
      onInputAmount,
      closeCircleOutline,
      initialCreditValue,
      priceFormatter,
      onApplyCreditBalance,
      afterCreditChangeValue
    };
  }
});
</script>
<style lang="scss" scoped>
ion-item {
  width: 95%;
  border: 1px solid rgb(139, 139, 139);
  border-radius: 10px;
  margin-bottom: 1rem;
}
.align-top {
  margin-top: -10px !important;
}
.box {
  width: 95%;
  border: 1px solid rgb(139, 139, 139);
  border-radius: 10px;
  margin-bottom: 1rem;
  margin: 0 auto;
}
.border-bottom {
  border-bottom: 1px solid var(--ion-color-text-gray-300);
}

.f-icon {
  color: gray;
}
.f-orange {
  color: #eb8c31;
}
ion-button {
  --border-radius: 10px;
  width: 95%;
  height: 44px;
}
ion-textarea {
  --placeholder-color: var(--ion-color-text-gray-600);
}
</style>
