<template>
  <div>
    <div class="mx-3 mt-4 d-flex flex-column">
      <ion-label class="text-gray-700 fw-500 pb-1">{{ label }}</ion-label>
      <ion-label class="text-gray-700 fs-2 fw-400 pb-2">{{ labelImgSuccess }}</ion-label>
    </div>
    <div class="box mx-3 flex-column mb-2" v-if="imgData.length > 0">
      <div class="row pa-2 mb-1" v-for="(item, i) in imgData" :key="i">
        <ion-img @click="onTapFullSizeImg(item.imgUrl)" class="proof-image" :src="item.imgUrl" />
        <div class="column right-content">
          <ion-text class="fw-500 text-gray-700 image-proof-name"> {{ item.imgName }}</ion-text>
          <ion-text class="text-gray-700 my-2">{{ item.imgSize }}</ion-text>
          <ion-button expand="block" @click="$emit('deleteImage', i)" color="danger" class="delete-btn w-100">
            <ion-icon :icon="trashOutline"> </ion-icon>
            <ion-label class="text-capitalize fs-13">
              {{ $t('delete') }}
            </ion-label>
          </ion-button>
        </div>
      </div>
    </div>
    <div class="box mx-3 flex-column py-5" @click="$emit('triggerBottomSheet')">
      <ion-button fill="outline" color="primary" @click="$emit('triggerBottomSheet')">
        <ion-icon :icon="cloudUploadOutline"></ion-icon>
        <ion-label class="text-capitalize pl-2">
          {{ labelUpload }}
        </ion-label>
      </ion-button>
      <ion-text class="fs-2 text-gray-700">
        {{ labelMaxfile }}
      </ion-text>
    </div>
  </div>
</template>
<script>
import { cloudUploadOutline, trashOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'CompPaymentProof',
  emits: ['deleteImage', 'triggerBottomSheet', 'onTapFullSizeImg'],
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const label = t('payment_proof');
    const labelUpload = t('upload_file');
    const labelMaxfile = t('max_file_size');
    const labelImgSuccess = t('image_success_transaction');
    const imgData = ref(props.data);
    const onTapFullSizeImg = (imgUrl) => {
      emit('onTapFullSizeImg', imgUrl);
    };
    return {
      label,
      labelImgSuccess,
      labelUpload,
      labelMaxfile,
      cloudUploadOutline,
      trashOutline,
      imgData,
      onTapFullSizeImg
    };
  }
});
</script>
<style lang="scss" scoped>
.box {
  border: 1px dashed var(--ion-color-text-gray-300);
  background-color: #fff;
  border-radius: 10px;
  min-height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
ion-button {
  --border-radius: 6px;
}
.fs-13 {
  font-size: 13px;
}
.w-100 {
  width: 100%;
}
.proof-image {
  margin-right: 16px;
  height: 26vw;
  width: 26vw;
  max-height: 90px;
  max-width: 90px;
  display: block;
  object-fit: cover;
}
@media only screen and (max-width: 280px) {
  .proof-image {
    margin-right: 12px;
    height: 26vw;
    width: 26vw;
    max-height: 60px;
    max-width: 60px;
    display: block;
    object-fit: cover;
  }
}
.image-proof-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.right-content {
  max-width: 68%;
}
@media only screen and (min-width: 652px) {
  .right-content {
    max-width: 88%;
  }
}
</style>
