<template>
  <ion-page>
    <ion-content>
      <ion-list>
        <ion-card class="mx-0 px-2 py-2">
          <ion-grid>
            <ion-row v-for="i in 5" :key="i">
              <ion-col size="auto">
                <ion-skeleton-text
                  :animated="true"
                  style="width: 17px; height: 17px; border-radius: 12px"
                ></ion-skeleton-text>
              </ion-col>
              <ion-col>
                <ion-skeleton-text
                  :animated="true"
                  style="width: 40%; height: 17px; border-radius: 12px"
                ></ion-skeleton-text>
                <ion-skeleton-text
                  :animated="true"
                  style="width: 65%; margin: 10px 0; height: 17px; border-radius: 12px"
                ></ion-skeleton-text>
                <ion-skeleton-text
                  :animated="true"
                  style="width: 40%; margin: 10px 0; height: 17px; border-radius: 12px"
                ></ion-skeleton-text>
                <ion-skeleton-text
                  :animated="true"
                  style="width: 90%; margin: 10px 0; height: 17px; border-radius: 12px"
                ></ion-skeleton-text>
                <ion-skeleton-text
                  :animated="true"
                  style="width: 50%; margin: 10px 0; height: 17px; border-radius: 12px"
                ></ion-skeleton-text>
                <ion-skeleton-text
                  :animated="true"
                  style="width: 40%; margin: 10px 0; height: 17px; border-radius: 12px"
                ></ion-skeleton-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<script>
export default {
  name: 'SkeletonLoading'
};
</script>
