<template>
  <ion-card class="mx-0">
    <ion-grid>
      <ion-row class="ion-align-items-center">
        <ion-col>
          <ion-row>
            <ion-skeleton-text
              :animated="true"
              style="width: 40%; height: 17px; border-radius: 12px"
            ></ion-skeleton-text>
          </ion-row>
          <ion-row>
            <ion-label>
              <ion-skeleton-text
                :animated="true"
                style="width: 4rem; height: 17px; border-radius: 12px"
              ></ion-skeleton-text>
            </ion-label>
          </ion-row>
        </ion-col>
        <ion-col size="auto">
          <ion-skeleton-text
            :animated="true"
            style="width: 8rem; height: 4vh; border-radius: 12px"
          ></ion-skeleton-text>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SkeletonBottom'
});
</script>
