<template>
  <div>
    <ion-checkbox slot="start" :disabled="disabled" :checked="checked" @ionChange="onToggle"></ion-checkbox>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'checkbox',
  emits: ['onToggle'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {};
  },
  methods: {
    onToggle(event) {
      this.$emit('onToggle', event.detail.checked);
    }
  }
});
</script>
<style lang="scss" scoped>
ion-checkbox {
  --size: 32px;
  --background-checked: #00676a;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #00676a;
}
</style>
