<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <div class="d-flex align-center justify-space-between">
          <ion-label class="fw-500 fs-3 pl-2">{{ data?.payment_number || '-' }}</ion-label>
          <ion-icon
            @click="$emit('closeModal')"
            class="text-gray-500 fs-5"
            :icon="closeCircleOutline"
          ></ion-icon>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true">
      <div class="w-100 bg-gray-modal h-100 pt-5 pb-5">
        <ion-grid class="w-90 rounded-sm px-3 pt-3 bg-white shadow-sm">
          <ion-row>
            <ion-col>
              <ion-label v-if="data.invoices.length > 0" class="text-gray-800">{{
                $t('invoices_amount')
              }}</ion-label>
              <ion-row class="mt-1" v-for="(invoice, i) in data?.invoices || []" :key="i">
                <ion-col>
                  <ion-label class="text-gray-700">{{ invoice?.invoice_number || '-' }}</ion-label>
                </ion-col>
                <ion-col size="auto">
                  <ion-label class="fw-600 text-gray-700">{{
                    priceFormat(invoice?.amount) || '-'
                  }}</ion-label>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
          <ion-row v-if="data.invoices.length > 0">
            <ion-col>
              <ion-label class="text-primary-green-700">{{ $t('applied_credits') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-primary-green-700">{{
                `-${data?.customer_credit_used > 0 ? priceFormat(data.customer_credit_used) : ''}`
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row v-if="data.invoices.length === 0">
            <ion-col>
              <ion-label class="text-gray-800">{{ $t('top_up_credits') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{
                priceFormat(data?.payment_amount) || '-'
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray-800">{{ $t('total_payment') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{
                priceFormat(data?.payment_amount) || '-'
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row v-if="data?.overpayment_amount && data?.invoices.length > 0">
            <ion-col>
              <ion-label class="text-gray-800">{{ $t('overpayment') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{
                priceFormat(data?.overpayment_amount) || '-'
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="border-top mt-2">
            <ion-col class="mt-2">
              <ion-label class="text-gray-800">{{ $t('payment_method') }}</ion-label>
            </ion-col>
            <ion-col size="auto" class="mt-2">
              <ion-label class="fw-600 text-gray-700">{{ data?.payment_method || '-' }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row
            v-if="
              [BANK_TRANSFER, CASH_DEPOSIT, CHEQUE, CASH_PAYMENT_COLLECTION].includes(data?.payment_method)
            "
          >
            <ion-col>
              <ion-label class="text-gray-800">{{ $t('transaction_date') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{
                formatDateMonthYear(data?.transaction_date) || '-'
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row
            class="my-2"
            v-if="[CHEQUE, CASH_PAYMENT_COLLECTION].includes(data?.payment_method) && data.reference_number"
          >
            <div class="box">
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-col>
                  <ion-row>
                    <ion-label class="ml-2 mt-1 text-gray-700 fw-bold"
                      >{{ $t('transaction_reference_number') }}
                    </ion-label>
                  </ion-row>
                  <ion-row>
                    <ion-text class="ml-2 mt-2 mb-2 text-gray-700"
                      >{{ data.reference_number || '-' }}
                    </ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </div>
          </ion-row>
          <ion-row v-if="[BANK_TRANSFER].includes(data?.payment_method)">
            <ion-col>
              <ion-label class="text-gray-800">{{ $t('payment_value_date') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{
                formatDateMonthYear(data?.value_date) || '-'
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label class="text-gray-800">{{ $t('payment_time') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-label class="fw-600 text-gray-700">{{
                formatDayDateTime(data?.payment_created_at) || '-'
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="my-2" v-if="[BANK_TRANSFER, CASH_DEPOSIT].includes(data?.payment_method)">
            <div class="box">
              <ion-row class="ion-align-items-center ion-justify-content-between">
                <ion-col>
                  <ion-row>
                    <ion-label class="ml-2 mt-1 text-gray-700 fw-bold">{{ $t('note_only') }} </ion-label>
                  </ion-row>
                  <ion-row>
                    <ion-text class="ml-2 mt-2 mb-2 text-gray-700">{{ data?.note || '-' }} </ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </div>
          </ion-row>
          <ion-row v-if="data?.payment_proof.length > 0">
            <ion-col>
              <ion-label class="text-gray-800">{{ $t('payment_proof') }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-row
              v-for="(img, i) in data?.payment_proof.length > 2
                ? data?.payment_proof.slice(0, 2)
                : data.payment_proof"
              :key="i"
            >
              <ion-col size="6">
                <ion-thumbnail @click="isOpenPreviewImages = true">
                  <img alt="img" :src="img" />
                </ion-thumbnail>
              </ion-col>
              <ion-col
                size="auto"
                v-if="data?.payment_proof.length > 2 && i === 1"
                class="d-flex align-center justify-center pl-4"
              >
                <ion-label class="fw-500 text-gray-700">
                  + {{ data.payment_proof.length - 2 + ` ${$t('faq_page.more')}` }}
                </ion-label>
              </ion-col>
            </ion-row>
          </ion-row>

          <ion-row v-if="data?.payment_proof?.length > 2">
            <ion-col size="12">
              <ion-button
                @click="isOpenPreviewImages = true"
                class="w-100 h-2rem"
                fill="outline"
                expand="block"
              >
                <ion-label class="text-capitalize"> {{ $t('view_all') }} </ion-label>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="border-top mt-2 pt-2">
            <ion-col>
              <ion-label class="fw-600 text-gray-700">{{ $t('total') }}</ion-label>
            </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-500" :class="PAYMENT_HISTORY_LIST_TEXT_COLOR[data?.payment_status]">
                {{ data?.payment_status || '-' }}</ion-text
              >
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col> </ion-col>
            <ion-col size="auto">
              <ion-text class="fw-700 text-gray-700">
                {{
                  priceFormat(
                    data?.invoices.length > 0 ? data?.total_invoice_paid_amount : data?.overpayment_amount
                  ) || '-'
                }}</ion-text
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
    <ion-loading :is-open="loadingCancelling" cssClass="custom-loading" spinner="crescent"> </ion-loading>
    <ion-modal :is-open="isOpenPreviewImages" @didDismiss="isOpenPreviewImages = false">
      <preview-image-collection :images="data?.payment_proof" @closePage="isOpenPreviewImages = false" />
    </ion-modal>
  </ion-page>
</template>
<script>
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import {
  MANUAL_PAYMENT_METHOD,
  PAYMENT_HISTORY_LIST_STATUS,
  PAYMENT_HISTORY_LIST_TEXT_COLOR
} from '@/modules/shared/constants/';
import { toastError, useAlert } from '@/modules/shared/utils/';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils';
import { closeCircleOutline } from 'ionicons/icons';
import { computed, defineAsyncComponent, defineComponent, inject, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ModalPaymentHistoryDetail',
  components: {
    PreviewImageCollection: defineAsyncComponent(() =>
      import('@/modules/shared/components/PreviewImageCollection.vue')
    )
  },
  emits: ['closeModal'],
  props: {
    currencySymbol: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const storage = inject('$storage');
    const { formatDayDateTime, formatDateMonthYear } = useDateFormatter();

    const { t } = useI18n();
    const store = useStore();
    const currSymbol = ref('');
    const payloadCancel = ref({ tenantId: null, customerId: null });
    const { createAlertTwoAction, createAlert } = useAlert();
    const getUser = async () => {
      await storage.getUser().then(({ currency_symbol, tenant }) => {
        currSymbol.value = currency_symbol;
        payloadCancel.value.tenantId = tenant.id;
      });
      await storage.getSelectedCompany().then(({ id }) => {
        payloadCancel.value.customerId = id;
      });
    };
    const { PAID, PENDING_APPROVAL } = PAYMENT_HISTORY_LIST_STATUS;
    const { BANK_TRANSFER, CASH_DEPOSIT, CHEQUE, CASH_PAYMENT_COLLECTION } = MANUAL_PAYMENT_METHOD;
    const onActionCancel = async () => {
      await store.dispatch(`sale/payment/${ACTIONS.CANCEL_MANUAL_PAYMENT}`, {
        batchPaymentId: props.data?.id,
        tenantId: payloadCancel.value.tenantId,
        customerId: payloadCancel.value.customerId
      });
    };
    const onCancelPayment = () => {
      createAlertTwoAction(
        t('cancel_submitted_payment'),
        t('cancel_submitted_payment_desc'),
        t('confirm_cancel_payment'),
        onActionCancel,
        t('close'),
        'alert-cancel-payment-history'
      );
    };
    const loadingCancelling = computed(() => store.getters[`sale/payment/isLoadingCancellingPayment`]);
    const errors = computed(() => store.getters[`sale/payment/error`]?.message ?? null);
    watch(loadingCancelling, (loadingProgress) => {
      if (!loadingProgress && !errors.value) {
        toastError(t('cancel_payment_success'));
      } else if (errors.value) {
        createAlert(t('unable_to_cancel'), t('payment_status_changed'));
      }
    });

    const isOpenPreviewImages = ref(false);
    onMounted(() => {
      getUser();
    });
    return {
      onCancelPayment,
      closeCircleOutline,
      formatDayDateTime,
      formatDateMonthYear,
      priceFormat: (price) => priceFormatter(currSymbol.value, price || 0),
      PAYMENT_HISTORY_LIST_TEXT_COLOR,
      PAID,
      PENDING_APPROVAL,
      BANK_TRANSFER,
      CASH_DEPOSIT,
      loadingCancelling,
      isOpenPreviewImages,
      CHEQUE,
      CASH_PAYMENT_COLLECTION
    };
  }
});
</script>
<style lang="scss" scoped>
ion-button {
  width: 95%;
  margin: 0 auto;
  --border-radius: 10px;
}

.border-top {
  border-top: 1px solid var(--ion-color-text-gray-300);
}

.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 7px;
  min-height: 56px;
  width: 100%;
}
.rounded-sm {
  border-radius: 7px;
}
.h-2rem {
  height: 2rem;
}
ion-thumbnail {
  --size: 90px;
  --border-radius: 5px;
}
</style>
