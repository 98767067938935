<template>
  <ion-card class="mx-0">
    <ion-card-content class="px-1 py-1">
      <ion-grid>
        <ion-row>
          <ion-col>
            <ion-label :class="PAYMENT_HISTORY_LIST_COLOR[data?.payment_status]" class="fs-2 fw-500">
              <ion-icon :icon="ellipse" class="fs-1" color="inherit"></ion-icon>
              {{ paymentStatusLabel }}
            </ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-label class="fs-2 fw-600">{{ data?.payment_method || '-' }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-label class="fs-2 fw-500">{{
              formatDayDateTime(data?.payment_created_at) || '-'
            }}</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-label class="fs-2 fw-bold"
              >{{ priceFormatter(currSymbol, data?.payment_amount ?? 0) || '-' }}
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-label class="fw-500 fs-2 text-primary-green-500">{{
              data?.payment_number || '-'
            }}</ion-label>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button
              @click="$emit('onShowPaymentSummaryDetail')"
              class="text-capitalize"
              size="small"
              fill="outline"
              >{{ $t('view_details') }}</ion-button
            >
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-card-content>
  </ion-card>
</template>
<script>
import { PAYMENT_HISTORY_LIST_COLOR } from '@/modules/shared/constants/';
import { useConfig } from '@/usecases/';
import { useDateFormatter } from '@/usecases/global';
import { priceFormatter } from '@/utils';
import { ellipse } from 'ionicons/icons';
import { computed, defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
  name: 'CardPaymentHistoryList',
  emits: ['onShowPaymentSummaryDetail'],
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const currSymbol = ref('');
    const { t, storage } = useConfig();
    const { formatDayDateTime } = useDateFormatter();
    const getUser = async () => {
      await storage.getUser().then(({ currency_symbol }) => {
        currSymbol.value = currency_symbol;
      });
    };
    const paymentStatusLabel = computed(() => {
      const label = {
        Paid: t('payment_status.paid'),
        Rejected: t('payment_status.rejected'),
        Cancelled: t('payment_status.cancelled'),
        'Pending Validation': t('payment_status.pending_validation'),
        'Pending Approval': t('payment_status.pending_approval')
      };
      return label[props.data?.payment_status] || `- `;
    });
    onMounted(() => {
      getUser();
    });
    return {
      formatDayDateTime,
      paymentStatusLabel,
      PAYMENT_HISTORY_LIST_COLOR,
      priceFormatter,
      currSymbol,
      ellipse
    };
  }
});
</script>
<style lang="scss" scoped>
ion-card {
  border-radius: none;
  border-bottom: 1px solid gray;
  box-shadow: none !important;
}
</style>
