<template>
  <ion-card class="mx-0 px-2 py-1">
    <ion-grid>
      <ion-row>
        <ion-col size="auto">
          <checkbox
            :disabled="invoice.isDisabled"
            :checked="!invoice.isDisabled && invoice.isSelected"
            @onToggle="onSelection"
          />
        </ion-col>
        <ion-col>
          <ion-row class="mb-1fr ion-justify-content-between">
            <ion-label class="fw-500 h-20px" :class="colorLabel">
              <ion-icon :icon="ellipse" class="fs-1" color="inherit"></ion-icon>
              {{ paymentStatusLabel }}</ion-label
            >
            <ion-col v-if="invoice.isDueToday && !invoice.isPaid" size="auto">
              <ion-label class="fs-2 fw-bold text-error-500">{{ $t('due_today') }}</ion-label>
            </ion-col>
            <ion-col v-if="invoice.isPaid" size="auto">
              <ion-label class="fs-2 fw-bold">{{
                priceFormatter(invoice.currencySymbol, invoice.amount)
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row class="ion-justify-content-between pt-1" v-if="invoice.isPendingApproval">
            <ion-label class="fw-500 h-20px" :class="PAYMENT_HISTORY_LIST_TEXT_COLOR[`Pending Approval`]">
              {{
                [INVOICE_PAYMENT_TYPE.PAYNOW, INVOICE_PAYMENT_TYPE.CREDIT_CARD].includes(
                  invoice.paymentTypeId
                )
                  ? $t('pending_validation').toUpperCase()
                  : $t('pending_payment_approval').toUpperCase()
              }}</ion-label
            >
          </ion-row>
          <ion-row class="ion-justify-content-between" v-if="!invoice.isPaid">
            <ion-text class="my-1 fw-500 text-gray-700"
              >{{ $t('credit_term') }}: {{ creditTermLabel }}</ion-text
            >
            <ion-label class="fs-2 fw-bold" :class="{ 'text-error-500': invoice.isOverdue }">{{
              priceFormatter(invoice.currencySymbol, invoice.outstandingAmount)
            }}</ion-label>
          </ion-row>

          <ion-row>
            <ion-text class="mb-1 fw-500 text-gray-900">
              #{{ invoice.invoiceNumber }} {{ $t('to') }} {{ invoice.tenantName }}
            </ion-text>
          </ion-row>

          <ion-row>
            <ion-text class="mb-1">{{ formatInvoiceDate(invoice.invoiceDate) }}</ion-text>
          </ion-row>

          <ion-row>
            <ion-button fill="outline" @click="$emit('onEnterViewInvoice')">
              <ion-label> {{ $t('view_invoice') }}</ion-label>
              <ion-icon color="primary" class="fs-3" :icon="chevronForwardOutline"></ion-icon>
            </ion-button>
          </ion-row>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card>
</template>
<script>
import Checkbox from '@/components/Checkbox.vue';
import {
  CARD_INVOICES_LIST_COLOR,
  INVOICE_PAYMENT_TYPE,
  PAYMENT_HISTORY_LIST_TEXT_COLOR
} from '@/modules/shared/constants/';
import { useDateFormatter } from '@/usecases/global/';
import { priceFormatter } from '@/utils/';
import { Browser } from '@capacitor/browser';
import { chevronForwardOutline, ellipse } from 'ionicons/icons';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CardInvoice',
  emits: ['onSelection', 'onEnterViewInvoice'],
  components: {
    Checkbox
  },
  props: {
    invoice: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      PAID_COLOR,
      UNPAID_COLOR,
      AWAITING_PAYMENT_COLOR,
      PENDING_APPROVAL_COLOR
    } = CARD_INVOICES_LIST_COLOR;
    const computedInvoice = computed(() => props.invoice);
    const { formatDayDate } = useDateFormatter();
    const colorLabel = ref('');
    const paymentStatusLabel = ref('');
    switch (true) {
      case computedInvoice.value.isPaid:
        colorLabel.value = PAID_COLOR;
        paymentStatusLabel.value = t('paid');
        break;
      case computedInvoice.value.isOverdue:
        colorLabel.value = UNPAID_COLOR;
        paymentStatusLabel.value = t('overdue');
        break;
      case computedInvoice.value.isDueToday:
        colorLabel.value = AWAITING_PAYMENT_COLOR;
        paymentStatusLabel.value = t('awaiting_payment');
        break;
      default:
        colorLabel.value = AWAITING_PAYMENT_COLOR;
        paymentStatusLabel.value = t('awaiting_payment');
    }
    const creditTermLabel = props.invoice?.creditTermName ?? '-';
    watch(
      computedInvoice,
      (newInvoice) => {
        switch (true) {
          case newInvoice.isPaid:
            colorLabel.value = PAID_COLOR;
            paymentStatusLabel.value = t('paid');
            break;
          case newInvoice.isOverdue:
            colorLabel.value = UNPAID_COLOR;
            paymentStatusLabel.value = t('overdue');
            break;
          case newInvoice.isDueToday:
            colorLabel.value = AWAITING_PAYMENT_COLOR;
            paymentStatusLabel.value = t('awaiting_payment');
            break;
          default:
            colorLabel.value = AWAITING_PAYMENT_COLOR;
            paymentStatusLabel.value = t('awaiting_payment');
        }
      },
      { deep: true }
    );

    return {
      chevronForwardOutline,
      ellipse,
      Browser,
      priceFormatter,
      colorLabel,
      paymentStatusLabel,
      creditTermLabel,
      PENDING_APPROVAL_COLOR,
      INVOICE_PAYMENT_TYPE,
      onSelection: (checked) => {
        emit('onSelection', props.invoice.id, checked);
      },
      PAYMENT_HISTORY_LIST_TEXT_COLOR,
      formatInvoiceDate: (date) =>
        t('invoice_date', {
          invoiceDate: formatDayDate(date)
        })
    };
  }
});
</script>
<style lang="scss" scoped>
ion-card {
  border-radius: none;
  border-top: 1px solid gray;
  box-shadow: none !important;
}

ion-button {
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  ion-label {
    text-transform: capitalize;
    font-size: 13px;
    margin-left: 5px;
  }
}

.h-20px {
  height: 20px;
}
</style>
