<template>
  <ion-content>
    <div class="mx-2">
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 75px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
    <ion-label>
      <ion-skeleton-text
        :animated="true"
        style="width: 55%; height: 15px; border-radius: 12px; margin-top: 0.8rem; margin-left: 0.7rem"
      ></ion-skeleton-text>
    </ion-label>
    <div class="mx-2" v-for="i in 5" :key="i">
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 45px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
    <ion-label>
      <ion-skeleton-text
        :animated="true"
        style="width: 55%; height: 15px; border-radius: 12px; margin-top: 0.8rem; margin-left: 0.7rem"
      ></ion-skeleton-text>
    </ion-label>
    <div class="mx-2" v-for="i in 5" :key="i">
      <ion-skeleton-text
        :animated="true"
        style="width: 100%; height: 45px; border-radius: 12px; margin: 0.8rem auto"
      ></ion-skeleton-text>
    </div>
  </ion-content>
</template>
<script>
export default {
  name: 'SkeletonSelectPayment'
};
</script>
