<template>
  <ion-footer>
    <ion-toolbar class="mx-0">
      <ion-grid>
        <ion-row class="ion-align-items-center">
          <ion-col>
            <ion-row>
              <ion-text>{{ $t('total_invoice_amount') }}</ion-text>
            </ion-row>
            <ion-row>
              <ion-label class="f-price">{{ formatPrice(totalInvoiceAmount) }}</ion-label>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-button color="primary" fill="solid" @click="$emit('handlePayInvoices')" :disabled="!canPay">
              <ion-label>{{ $t('pay_invoices') }} ({{ totalSelectedInvoices }})</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-footer>
</template>
<script>
import { priceFormatter } from '@/utils/';
import { computed, defineComponent } from 'vue';
export default defineComponent({
  name: 'CompBottomContent',
  emits: ['handlePayInvoices'],
  props: {
    totalInvoiceAmount: {
      type: Number,
      default: 0,
      required: true
    },
    totalSelectedInvoices: {
      type: Number,
      default: 0,
      required: true
    },
    currencySymbol: {
      type: String,
      default: '',
      required: true
    }
  },
  setup(props) {
    return {
      canPay: computed(() => props.totalSelectedInvoices > 0),
      formatPrice: (price) => priceFormatter(props.currencySymbol, price)
    };
  }
});
</script>
<style lang="scss" scoped>
ion-button {
  width: fit-content;
  --border-radius: 10px;
  height: 44px;
  ion-label {
    padding: 2rem 0;
  }
}
ion-label {
  text-transform: capitalize;
}
.f-price {
  font-weight: bold;
  font-size: 22px;
  margin-top: 0.7rem;
}
ion-card {
  margin-bottom: 1px;
  border: none;
}
</style>
